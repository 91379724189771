










import { Component, Vue } from "vue-property-decorator";

@Component({
  metaInfo: {
    title: "About Seed Subsidy Program",
    meta: [
      { name: "keyword", content: "about,page" },
      {
        name: "description",
        content: "About Page of SQCC App",
        vmid: "about"
      }
    ]
  }
})
export default class AboutSeedSubsidyProgram extends Vue {}
