

































































































































































































































































import { Button } from "element-ui";
import Appfooter from "@/components/UIComponents/Appfooter.vue";
import { Vue, Component } from "vue-property-decorator";
import { eventHub } from "@/utils/eventHub";
import LoginModal from "@/components/UIComponents/LoginModal.vue";
import DevelopersCredit from "@/components/UIComponents/DeveloperCredits.vue";
import Logo from "@/components/UIComponents/Logo.vue";
import {
  JWTStore,
  UserStore,
  LoadStore,
  CropVarietyStore,
  CompareCropStore,
  MetaStore,
  CropStore,
  CommentStore
} from "@/store/modules";
import { constants } from "crypto";
import { RawLocation, Location } from "vue-router";
import { MetaInfo } from "vue-meta";
let styles = require("@/stylesheets/sass/_export.sass");

@Component({
  components: { LoginModal, Appfooter, Logo, DevelopersCredit },
  metaInfo(): MetaInfo {
    return {
      titleTemplate: `%s - ${this.$t("seedSubsidyProgram")}`,
      meta: [
        { charset: "utf-8" },
        { name: "viewport", content: "width=device-width, initial-scale=1" }
      ]
    };
  }
})
export default class Home extends Vue {
  // isLoggedIn: boolean = JWTStore.loggedIn;
  // isSQCC: boolean = JWTStore.sqcc;
  mainIndex: number = 4;
  getItemIndex: number = 3;
  navMenuIndex: string = "";
  isNepali: boolean = false;
  styles: any = styles;

  data() {
    return {
      seemenu: false,
      activeName: ""
    };
  }

  get mobileScreen() {
    return MetaStore.mobileScreen;
  }

  get reload() {
    return MetaStore.reload;
  }

  set reload(value: boolean) {
    MetaStore.setReload(value);
  }

  get crops() {
    return CropStore.CropNameList;
  }

  get unseenStatus() {
    return CommentStore.UnseenStatus;
  }

  get user() {
    return UserStore.User;
  }

  registerScreen() {
    this.$router
      .push({
        name: "Chooserole",
        params: { lang: this.$i18n.locale }
      })
      .catch(error => {});
  }

  getItems(categ: any, i: number) {
    let index = this.getItemIndex + i;
    if (categ["Meta"] !== undefined) {
      return Object.keys(categ["Meta"])[index];
    }
  }

  getData(categ: any, data: string) {
    if (categ["Meta"] !== undefined) {
      if (categ["Meta"][data] !== []) {
        return categ["Meta"][data];
      }
    }
  }

  checkLength(categ: any, data: string) {
    if (categ["Meta"] !== undefined) {
      if (categ["Meta"][data].length === 0) {
        return 0;
      } else {
        return categ["Meta"][data].length;
      }
    }
  }

  getKeys(categ: any) {
    if (categ["Meta"] !== undefined) {
      // return Object.keys(categ["Meta"]).slice(
      //   this.getItemIndex,
      //   Object.keys(categ["Meta"]).length
      // );
      let keys = Object.keys(categ["Meta"]).filter(x => typeof categ["Meta"][x] !== "string");
      return keys;
    }
  }

  getSubIndex(index: number, i: number) {
    return String(index) + "-" + String(i + 1);
  }

  getIndex(index: number) {
    if (index !== undefined || index !== null) {
      return String(this.mainIndex + index);
    }
  }

  getMenuIndex(index: number) {
    if (index !== undefined || index !== null) {
      return String(index + 1);
    }
  }

  getMenuItems(categ: any) {
    if (categ["Meta"] !== undefined) {
      let keys = Object.keys(categ["Meta"]).filter(x => typeof categ["Meta"][x] === "string");
      this.getItemIndex = keys.length;
      this.mainIndex = keys.length + 1;
      return keys;
    }
  }

  get isLoggedIn() {
    return JWTStore.isAuthenticated;
  }

  get isSQCC() {
    return JWTStore.role === "SQCCAdmin" || null;
  }

  get isViewer() {
    return JWTStore.role === "DLAO";
  }

  get isDOADViewer() {
    return JWTStore.role === "DOADViewer";
  }

  get isDOAAdmin() {
    return JWTStore.role === "DOAAdmin";
  }

  get isISM() {
    return JWTStore.role === "ISMAdmin" || null;
  }

  get isISSMorSSSM() {
    return (
      JWTStore.role === "ISSMAdmin" ||
      JWTStore.role === "SSSMAdmin" ||
      JWTStore.role === "MoALD" ||
      null
    );
  }

  get isSeedCompanyApproved() {
    if (!this.user.approved) {
      return false;
    }
    return true;
  }

  get isSeedCompany() {
    return (
      JWTStore.role === "supplier" ||
      JWTStore.role === "consumer" ||
      JWTStore.role === "improved seed consumer" ||
      JWTStore.role === "source_seed_producer" ||
      JWTStore.role === "improved_seed_producer" ||
      JWTStore.role === "improved_seed_distributor" ||
      JWTStore.role === "improved_seed_consumer" ||
      null
    );
  }

  get language() {
    return this.$i18n.locale;
  }

  get cropCategoryNavBar() {
    return CropStore.CropCategories;
  }

  handleSelect(key: any, keyPath: any) {
    this.navMenuIndex = key;
  }

  emitGloballogin() {
    eventHub.$emit("loginClick");
  }

  set language(lang: any) {
    this.$i18n.locale = lang;
    this.$moment.locale(this.$i18n.locale);
    let params = this.$route.params;
    params.lang = lang;

    if (this.$route.name) {
      this.$router
        .replace({
          name: this.$route.name,
          query: this.$route.query,
          params: this.$route.params
        })
        .catch(error => {});
    }
    this.reloader();
  }

  get username() {
    let username = this.user.name;
    if (this.$i18n.locale == "ne") {
      if (this.user.nepname && this.user.nepname != "") {
        username = this.user.nepname;
      }
    }
    return username;
  }

  get userProfilePicture() {
    return UserStore.User.picture;
  }

  reloader() {
    this.reload = true;
    this.$nextTick(() => {
      this.reload = false;
    });
  }

  routeTo(location: Location, index: string) {
    this.navMenuIndex = index;
    if (this.$route.name != location.name) {
      this.$router.push(location).catch(error => {});
    }
  }

  hrefTo(url: string) {
    window.open(url, "_blank");
  }

  logout() {
    JWTStore.logOut();
  }

  screenResize() {
    /**
     * This function checks if screen less than 576px (mobile screen)
     * And then set MetaStore's mobileScreen value
     */
    if (document.body.clientWidth <= 576) {
      MetaStore.setMobileScreen(true);
    } else {
      MetaStore.setMobileScreen(false);
    }
  }

  async created() {
    /**
     * This is done to check if screen less than 576px (mobile screen)
     * And then set MetaStore's mobileScreen which can be used in vue components
     * like dialog box and made into fullscreen
     */
    window.addEventListener("resize", this.screenResize);
    this.screenResize();

    /** Set moment's locale */
    this.$moment.locale(this.$i18n.locale);

    eventHub.$on("reloadPage", this.reloader);

    if (localStorage.getItem("access_token")) {
      await UserStore.getUser();
    }
    LoadStore.hideLoading();
  }

  async cropTypeClick(cropSlug: string, cropName: string) {
    let cropId = await MetaStore.getCropIdBySlug(cropSlug);
    CropVarietyStore.saveFilterQuery({});
    CropVarietyStore.setSorter(null);
    CropVarietyStore.setPageNumber(1);
    CropVarietyStore.setCropIdInList(cropId);
    CompareCropStore.clearSelectedCrops();
    this.$router
      .push({
        name: "ListingScreen",
        params: {
          cropSlug: String(cropSlug)
        }
      })
      .catch(error => {});
    CropStore.getCropById({ id: cropId, lang: this.$i18n.locale });
    CropVarietyStore.getCropVarietyFilterables({
      cropId: cropId,
      lang: this.$i18n.locale
    });
    CropVarietyStore.getSortableVariables({
      cropId: cropId,
      lang: this.$i18n.locale
    });
    LoadStore.showLoadingCropVarieties();
    await CropVarietyStore.getAllCropVarietiesDetail({
      cropid: String(cropId),
      lang: this.$i18n.locale
    });
    LoadStore.hideLoadingCropVarieties();
  }
}
