




































































































































import { Component, Prop, Vue } from "vue-property-decorator";
import { eventHub } from "@/utils/eventHub";
import { nepaliUnicodeNumber } from "@/store/modules/dateConverter";

@Component
export default class Appfooter extends Vue {
  developersCredit() {
    eventHub.$emit("developersCredit");
  }
  nepaliUnicodeNumber(value: any) {
    if (this.$i18n.locale == "ne") {
      return nepaliUnicodeNumber(value);
    }
    return value;
  }
}
