















































































































































































































import { Vue, Component } from "vue-property-decorator";
import { UserStore, DSADStore, JWTStore, MetaStore } from "@/store/modules";
import { User } from "@/store/models/user";
import { DSADGroup } from "@/store/models/dsad";
import { nepaliUnicodeNumber } from "@/store/modules/dateConverter";

@Component
export default class DsadNotice extends Vue {
  loadingSupply: boolean = false;
  loadingDemand: boolean = false;
  searchString: string = "";
  pageNum: number = 1;

  get mobileScreen() {
    return MetaStore.mobileScreen;
  }

  get supplyNotices() {
    return DSADStore.DSADSupplyNoticeList;
  }

  get demandNotices() {
    return DSADStore.DSADDemandNoticeList;
  }

  private async created() {
    if (this.$route.query.page) {
      this.pageNum = Number(this.$route.query.page);
    }

    this.loadingSupply = true;
    this.loadingDemand = true;
    await DSADStore.getDSADSupplyNotice({
      lang: this.$i18n.locale
    });
    this.loadingSupply = false;

    await DSADStore.getDSADDemandNotice({
      lang: this.$i18n.locale
    });
    this.loadingDemand = false;
  }

  async handlePrevNextClick(val: any) {
    this.loadingSupply = true;
    await DSADStore.getDSADSupplyNotice({
      search: this.searchString,
      page: val,
      lang: this.$i18n.locale
    });
    this.loadingSupply = false;

    // if (this.mobileScreen) {
    //   this.$refs.dasdNoticeCard[0].$el.scrollIntoView({ behavior: "smooth", block: "start" });
    // }
    this.$el.scrollIntoView({ behavior: "smooth", block: "start" });
  }

  // toggleMore(item: any) {
  //   if (item.currentListLength == this.maxListLength) {
  //     item.currentListLength = -1;
  //   } else {
  //     item.currentListLength = this.maxListLength;
  //   }
  // }
}
