




















import { Component, Vue } from "vue-property-decorator";
import { MetaInfo } from "vue-meta";

@Component({
  metaInfo(): MetaInfo {
    return {
      title: `${this.$t("download")}`,
      meta: [
        { name: "keyword", content: "download,page" },
        {
          name: "description",
          content: "Downloads",
          vmid: "homeScreen"
        }
      ]
    };
  }
})
export default class Downloads extends Vue {
  baseUrl: string = process.env.VUE_APP_API_BASE || "/";
  get downloadFiles() {
    return [
      {
        name: this.$t("nirdeshika"),
        url: `${this.baseUrl}static/download_documents/nirdeshika.pdf`,
        icon: "el-icon-s-management"
      },
      {
        name: this.$t("manual"),
        url: `${this.baseUrl}static/download_documents/manual.pdf`,
        icon: "el-icon-document-copy"
      }
    ];
  }

  created() {
    if (!this.baseUrl.endsWith("/")) {
      this.baseUrl += "/";
    }
  }
}
