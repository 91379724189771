











import { Button } from "element-ui";
import { Vue, Component } from "vue-property-decorator";
import Homeslider from "@/components/UIComponents/Homeslider.vue";
import HomeScreenChooseRole from "@/components/UIComponents/HomeScreenChooseRole.vue";
// import RecentlyApprovedCompany from "@/components/UIComponents/RecentlyApprovedCompany.vue";
import DSADNotice from "@/components/UIComponents/DSADNotice.vue";
import SubsidyNotice from "@/components/UIComponents/SubsidyNotice.vue";
import { CropStore, LoadStore } from "@/store/modules";
import Cropstat from "@/components/UIComponents/Cropstat.vue";
import Recentvarieties from "@/components/UIComponents/Recentvarieties.vue";
import aboveFooter from "@/components/UIComponents/aboveFooter.vue";
import { MetaInfo } from "vue-meta";

@Component({
  components: {
    Button,
    Homeslider,
    HomeScreenChooseRole,
    DSADNotice,
    SubsidyNotice,
    // RecentlyApprovedCompany,
    Cropstat,
    Recentvarieties,
    aboveFooter
  },
  metaInfo(): MetaInfo {
    return {
      title: `${this.$t("home")}`,
      meta: [
        { name: "keyword", content: "home,page" },
        {
          name: "description",
          content: "Home Page of SQCC App",
          vmid: "homeScreen"
        }
      ]
    };
  }
})
export default class Home extends Vue {
  loading: boolean = true;

  private get crops() {
    return CropStore.CropNameList;
  }

  private get cropslide() {
    let crops = CropStore.Crops;
    let cropList: any[] = [];
    if (crops.length > 0) {
      for (let crop of crops) {
        cropList.push({
          id: crop.Id,
          title: crop.Name,
          content: crop.NumVarieties + " " + String(this.$tc("variety", crop.NumVarieties)),
          image: crop.Image,
          url: this.$router.resolve({
            name: "ListingScreen",
            params: { cropSlug: crop.Slug }
          }).href
        });
      }
    }
    return cropList;
  }

  private async created() {
    // this.loading = true;
    // await CropStore.getAllCrops({ lang: this.$i18n.locale });
    // this.loading = false;
  }
}
