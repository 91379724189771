











































































import { Component, Prop, Vue } from "vue-property-decorator";
import { eventHub } from "@/utils/eventHub";
import { Input } from "element-ui";
import { JWTStore, UserStore, MetaStore } from "@/store/modules";
import { userInfo } from "@/store/models/jwt";
import { setJWT } from "@/store/API/api";
import FacebookLogin from "@/views/Authscreen/FacebookLogin.vue";
import GoogleLogin from "@/views/Authscreen/GoogleLogin.vue";
import { ElementUIComponent } from "element-ui/types/component";
import { roleChecker } from "@/utils/jwt";

@Component({
  components: {
    Input,
    FacebookLogin,
    GoogleLogin
  }
})
export default class LoginModal extends Vue {
  isLoggedIn: boolean = JWTStore.loggedIn;
  dialogVisible: boolean = false;
  logging: boolean = false;
  closeButton: boolean = true;
  social: boolean = true;
  labelPosition: string = "top";
  message: string = "";
  payload: userInfo = {
    username: "",
    password: ""
  };

  get mobileScreen() {
    return MetaStore.mobileScreen;
  }

  get user() {
    return UserStore.User;
  }

  checkIfRequired(event: any) {
    let index = this.$validator.fields.items.findIndex(x => x.name == event);
    if (index >= 0 && this.$validator.fields.items[index].flags["required"]) {
      return true;
    }
    return false;
  }

  forgotPassword() {
    this.dialogVisible = false;
    this.$router.push({ name: "forgotpassword" }).catch(error => {});
  }

  onLoginClick(e: any, message?: any) {
    document.body.style.overflow = "hidden";
    window.scrollTo({ left: 0, top: 0, behavior: "smooth" });
    if (e == "companyUser") {
      this.closeButton = false;
    }
    if (e == "companyUser") {
      this.social = false;
    }
    if (message) {
      this.message = message;
    }
    this.$validator.reset();
    this.dialogVisible = true;
  }

  dialogClose(e: any) {
    document.body.style.overflow = "auto";
  }

  onRegisterClick() {
    this.dialogVisible = false;
  }

  normal_case(value: string) {
    if (value) {
      let splittedValue = value.split("_");
      let translatedValue = [];
      for (let value of splittedValue) {
        translatedValue.push(this.$t(value).toString());
      }
      value = translatedValue.join(" ");
    }
    return value;
  }

  async login(e: any) {
    let isValid = await this.$validator.validateAll();
    if (isValid) {
      this.logging = true;
      let result = await JWTStore.getJWT(this.payload);
      this.logging = false;
      await UserStore.getUser();
      eventHub.$emit("reloadPage");
      this.dialogVisible = false;
      this.dialogClose(false);
      this.$notify({
        title: this.$t("success").toString(),
        message: `${this.$t("loginMessage")}: ` + this.normal_case(this.user.username),
        type: "success"
      });
      if (
        roleChecker([
          "consumer",
          "supplier",
          "improved seed consumer",
          "source_seed_producer",
          "improved_seed_producer",
          "improved_seed_distributor",
          "improved_seed_consumer"
        ])
      ) {
        this.$router.push({ name: "CompanyMenu" });
      }
    }
  }

  async social_auth(credentials: any) {
    await JWTStore.social_auth(credentials);
    await UserStore.getUser();
    this.dialogVisible = false;
  }

  created() {
    eventHub.$on("loginClick", this.onLoginClick);
  }
}
