

















































































































































































































import { Vue, Component } from "vue-property-decorator";
import { UserStore, DSADStore, JWTStore, MetaStore, DemandSupplyStore } from "@/store/modules";
import { User } from "@/store/models/user";
import { DSADGroup } from "@/store/models/dsad";
import { nepaliDate, nepaliUnicodeNumber } from "@/store/modules/dateConverter";

@Component
export default class DsadNotice extends Vue {
  loading: boolean = false;
  searchString: string = "";
  pageNum: number = 1;

  unitName: any = {
    pkg: `${this.$t("per")} ${this.$t("kg")}`,
    pcent: `${this.$t("per")} %`
  };

  get mobileScreen() {
    return MetaStore.mobileScreen;
  }

  get notices() {
    return DemandSupplyStore.SubsidyNotice;
  }

  get sourceSeedSchedule() {
    return DemandSupplyStore.SourceSeedSubsidySchedule;
  }

  get improvedSeedSchedule() {
    return DemandSupplyStore.ImprovedSeedSubsidySchedule;
  }
  nepaliUnicodeNumber(value: any) {
    if (this.$i18n.locale == "ne" && value) {
      return nepaliUnicodeNumber(value);
    }
    return value;
  }

  nepaliDate(date: string) {
    return nepaliDate(date, this.$i18n.locale);
  }

  indexMethod(index: number) {
    return this.nepaliUnicodeNumber(index + 1);
  }

  async created() {
    if (this.$route.query.page) {
      this.pageNum = Number(this.$route.query.page);
    }

    this.loading = true;
    await DemandSupplyStore.getImprovedSeedSubsidySchedule();
    await DemandSupplyStore.getSourceSeedSubsidySchedule();
    this.loading = false;

    this.loading = true;
    await DemandSupplyStore.getSubisdyNotice({
      lang: this.$i18n.locale
    });
    this.loading = false;
  }
}
