











































































































import { Component, Prop, Vue } from "vue-property-decorator";
import { CompanyUser } from "@/store/models/user";

@Component
export default class Chooserole extends Vue {
  role: string = "";
  user: CompanyUser = {} as CompanyUser;

  proceesrole() {
    if (this.role == "") {
      this.$message("Please select at least one option");
      return;
    } else if (this.role == "producer") {
      this.$router
        .push({
          name: "Registersteps",
          params: { lang: this.$i18n.locale }
        })
        .catch(error => {});
    } else {
      this.$router
        .push({
          name: "Register",
          params: { lang: this.$i18n.locale }
        })
        .catch(error => {});
    }
  }

  proceedImprovedSeedRole() {
    this.$router
      .push({
        name: "ImprovedSeedRegisterSteps",
        params: { role: "improved seed consumer", lang: this.$i18n.locale }
      })
      .catch(error => {});
  }
}
